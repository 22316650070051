import { FC } from 'react';

import { getCardFilters } from '@/utils';
import { useCurrentUser, useUpdateGridSearchParams, useUserStatistics } from '@/hooks';
import { CardFilterOptions, GridSearchParamsTypes, Paths } from '@/consts';
import { TicketGridFilterInput, UserStatisticsResponse } from '@/__generated__';

import { TicketsSummary } from '../TicketsSummary';

interface IMainTicketsSummary {
  containerPadding?: string;
}

export const MainTicketsSummary: FC<IMainTicketsSummary> = ({ containerPadding = null }) => {
  const { id } = useCurrentUser();
  const [updateGridSearchParams] = useUpdateGridSearchParams();

  const { data: userStatistics } = useUserStatistics({ id });
  const ownedCards = [CardFilterOptions.OWNED_OPEN, CardFilterOptions.OWNED_OVERDUE];

  const handleCardClick = (clickedCardKey: keyof UserStatisticsResponse) => {
    const cardFilters = getCardFilters(clickedCardKey);
    // @ts-ignore
    const payload: TicketGridFilterInput = {
      ...cardFilters,
      [ownedCards.includes(clickedCardKey) ? 'ownerUserId' : 'assignedUserId']: { value: [id] },
    };

    updateGridSearchParams(
      {
        type: GridSearchParamsTypes.UPDATE_FILTER,
        payload,
      },
      `${Paths.TICKETS}/list`,
    );
  };

  const numberOfTickets = (key: keyof UserStatisticsResponse) =>
    userStatistics ? userStatistics[key] : 0;

  return (
    <TicketsSummary
      handleCardClick={handleCardClick}
      numberOfTickets={numberOfTickets}
      containerPadding={containerPadding}
      showOwned={true}
    />
  );
};
