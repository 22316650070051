import { gql } from '@apollo/client';

import { DOMAIN_BASIC_FRAGMENT } from './domainBasic';
import { DOMAIN_VIEW_FRAGMENT } from './domainView';
import { DEAL_IN_THE_DOMAIN_LIST_FRAGMENT } from './dealInTheDomainList';

export const DOMAIN_IN_THE_LIST_FRAGMENT = gql`
  fragment domainInTheList on Domain {
    ...domainView

    full_url
    action_plan_link
    content_plan_link
    linkbuilding_strategy
    linkbuilding_strategy_name
    diversification_linkbuilding_strategy
    diversification_linkbuilding_strategy_name
    brief_copy
    comment_copy
    seo_comment
    linkbuilding_diversification_spreadsheet
    content_marketing_spreadsheet
    updated_at
    cms {
      id
      value
    }
    days_to_end_quarter
    language
    language_name
    categories
    categories_names
    countable_texts
    countable_texts_names
    country
    country_name
    service_type
    service_type_name
    first_deal_start_date
    percentage_of_positioning

    lastQuarter {
      start_date
      end_date
      created_characters
      characters_to_create
      type
    }

    related {
      ...domainBasic
    }

    newestGridDeal {
      ...dealInTheDomainList
    }
    reportSettings {
      google_ads_account_id
    }
  }
  ${DOMAIN_BASIC_FRAGMENT}
  ${DOMAIN_VIEW_FRAGMENT}
  ${DEAL_IN_THE_DOMAIN_LIST_FRAGMENT}
`;
