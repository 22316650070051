import styled from 'styled-components';
import { Divider as DividerAntd } from 'antd';

import {
  Alert,
  CalendarWithClock,
  CheckmarkInSquare,
  ClockWithAlert,
  Domains,
  Folder,
  FolderWithAlert,
  FolderWithArrow,
} from '../Icon';

export const SummaryContainer = styled.div<{ padding: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ padding }) => padding};
  background-color: ${({ theme: { colors } }) => colors.cardBackground};
`;

export const Header = styled.h1`
  align-self: flex-start;
  margin: 0 0 20px;
  letter-spacing: 0.33px;
  font-size: ${({ theme: { font } }) => font.size.semiBig};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
`;

export const SubHeader = styled.h3`
  align-self: flex-start;
  margin: 0 0 10px;
  letter-spacing: 0.33px;
  font-size: ${({ theme: { font } }) => font.size.mediumPlus};
  font-weight: ${({ theme: { font } }) => font.weight.semiBold};
`;

export const Divider = styled(DividerAntd)`
  border-color: ${({ theme: { colors } }) => colors.selectBorder};
`;

export const CardsWrapper = styled.div<{
  smallCards: boolean;
  ownedCards: boolean | null | undefined;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${({ ownedCards }) => (ownedCards ? '5' : 'auto-fit')},
    minmax(${({ smallCards }) => (smallCards ? '170px' : '210px')}, 1fr)
  );
  width: 100%;
  .card-icon {
    overflow: visible;
  }
  &.small-cards-wrapper {
    .card-icon {
      padding: 6px;
    }
  }
`;

export const FolderIcon = styled(Folder)`
  fill: ${({ theme: { colors } }) => colors.white};
  border-radius: 100%;
  padding: 13px;
  background-color: ${({ theme: { colors } }) => colors.mainContrastColor};
`;

export const CheckmarkIcon = styled(CheckmarkInSquare)`
  fill: ${({ theme: { colors } }) => colors.white};
  border-radius: 100%;
  padding: 13px;
  background-color: ${({ theme: { colors } }) => colors.iconGrey};
`;

export const AlertIcon = styled(Alert)`
  fill: ${({ theme: { colors } }) => colors.white};
  border-radius: 100%;
  padding: 13px;
  background-color: ${({ theme: { colors } }) => colors.notifRed};
`;

export const ClockAlertIcon = styled(ClockWithAlert)`
  fill: ${({ theme: { colors } }) => colors.white};
  border-radius: 100%;
  padding: 13px;
  background-color: ${({ theme: { colors } }) => colors.darkOrange};
`;

export const CalendarClockIcon = styled(CalendarWithClock)`
  fill: ${({ theme: { colors } }) => colors.white};
  border-radius: 100%;
  padding: 13px;
  background-color: ${({ theme: { colors } }) => colors.purple};
`;

export const FolderArrowIcon = styled(FolderWithArrow)`
  fill: ${({ theme: { colors } }) => colors.white};
  border-radius: 100%;
  padding: 13px;
  background-color: ${({ theme: { colors } }) => colors.mainContrastColor};
`;

export const FolderAlertIcon = styled(FolderWithAlert)`
  fill: ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  padding: 13px;
  background-color: ${({ theme: { colors } }) => colors.notifRed};
`;

export const DomainsIcon = styled(Domains)`
  height: 40px;
  fill: ${({ theme: { colors } }) => colors.green};
`;
