import { ComponentProps, FC } from 'react';
import CountUp from 'react-countup';

import { theme } from '@/theme';
import { UserStatisticsResponse } from '@/__generated__';
import { CardFilterOptions } from '@/consts';

import { Card, ContentWrapper, TicketNumber, TicketTitle } from './styled';

interface ITicketCard {
  handleCardClick?: ((cardKey: keyof UserStatisticsResponse | CardFilterOptions) => void) | null;
  title: string;
  icon: React.FunctionComponent<ComponentProps<'svg'>>;
  number: number;
  cardKey: keyof UserStatisticsResponse | CardFilterOptions;
  smallCards: boolean;
}

export const TicketCard: FC<ITicketCard> = ({
  handleCardClick = null,
  icon: Icon,
  cardKey,
  title,
  number = 0,
  smallCards,
}) => {
  const handleClick = () => {
    !!handleCardClick && handleCardClick(cardKey);
  };

  const iconHeight = smallCards ? '40px' : '54px';

  const cardProps = {
    ...(!!handleCardClick ? { hoverable: true } : {}),
    bgcolor: !handleCardClick ? theme.colors.white : null,
    cardheight: smallCards ? '70px' : '90px',
  };

  return (
    <Card onClick={handleClick} {...cardProps}>
      <Icon className={'card-icon'} style={{ height: iconHeight, width: iconHeight }} />
      <ContentWrapper data-testid={`${cardKey}-card`}>
        <TicketTitle smallCards={smallCards}>{title}</TicketTitle>
        <TicketNumber smallCards={smallCards}>
          <CountUp end={number} />
        </TicketNumber>
      </ContentWrapper>
    </Card>
  );
};
