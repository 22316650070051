import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { CardFilterOptions } from '@/consts';
import { UserStatisticsResponse } from '@/__generated__';

import { TicketCard } from '../TicketCard';

import { i18nMessages } from './consts';

import {
  AlertIcon,
  CalendarClockIcon,
  CardsWrapper,
  CheckmarkIcon,
  ClockAlertIcon,
  Divider,
  DomainsIcon,
  FolderAlertIcon,
  FolderArrowIcon,
  FolderIcon,
  Header,
  SubHeader,
  SummaryContainer,
} from './styled';

interface ITicketsSummary {
  handleCardClick?: (key: keyof UserStatisticsResponse | CardFilterOptions) => void;
  numberOfTickets: (key: keyof UserStatisticsResponse | CardFilterOptions) => number;
  title?: string;
  containerPadding?: string | null;
  smallCards?: boolean;
  showDomains?: boolean;
  showOwned?: boolean;
}

export const TicketsSummary: FC<ITicketsSummary> = ({
  handleCardClick,
  numberOfTickets,
  title = null,
  containerPadding = null,
  smallCards = false,
  showDomains = false,
  showOwned = false,
}) => {
  const intl = useIntl();
  const { OPEN, OVERDUE, TODAY, NEXT_7_DAYS, CLOSED, DOMAINS, OWNED_OPEN, OWNED_OVERDUE } =
    CardFilterOptions;

  const ticketCards: {
    key: keyof UserStatisticsResponse | CardFilterOptions;
    title: string;
    icon: ReactNode;
  }[] = [
    {
      key: OPEN,
      title: intl.formatMessage(i18nMessages.open),
      icon: FolderIcon,
    },
    {
      key: CLOSED,
      title: intl.formatMessage(i18nMessages.closed),
      icon: CheckmarkIcon,
    },
    {
      key: OVERDUE,
      title: intl.formatMessage(i18nMessages.overdue),
      icon: AlertIcon,
    },
    {
      key: TODAY,
      title: intl.formatMessage(i18nMessages.today),
      icon: ClockAlertIcon,
    },
    {
      key: NEXT_7_DAYS,
      title: intl.formatMessage(i18nMessages.thisWeek),
      icon: CalendarClockIcon,
    },
    ...(showDomains
      ? [
          {
            key: DOMAINS,
            title: intl.formatMessage(i18nMessages.domains),
            icon: DomainsIcon,
          },
        ]
      : []),
  ];

  const ticketOwnedCards: {
    key: keyof UserStatisticsResponse | CardFilterOptions;
    title: string;
    icon: ReactNode;
  }[] = [
    {
      key: OWNED_OPEN,
      title: intl.formatMessage(i18nMessages.ownedOpen),
      icon: FolderArrowIcon,
    },
    {
      key: OWNED_OVERDUE,
      title: intl.formatMessage(i18nMessages.ownedOverdue),
      icon: FolderAlertIcon,
    },
  ];

  const padding = containerPadding ?? '30px 40px';

  return (
    <SummaryContainer padding={padding}>
      <Header>{title ?? intl.formatMessage(i18nMessages.title)}</Header>
      {showOwned && <SubHeader>{intl.formatMessage(i18nMessages.assigned)}</SubHeader>}
      <CardsWrapper
        data-testid="tickets-sumary-cards-wrapper"
        className={smallCards ? 'small-cards-wrapper' : 'cards-wrapper'}
        smallCards={smallCards}
      >
        {ticketCards.map(({ key, ...props }) => (
          <TicketCard
            key={key}
            cardKey={key}
            handleCardClick={handleCardClick}
            number={numberOfTickets(key)}
            smallCards={smallCards}
            {...props}
          />
        ))}
      </CardsWrapper>
      {showOwned && (
        <>
          <Divider />
          <SubHeader>{intl.formatMessage(i18nMessages.owned)}</SubHeader>
          <CardsWrapper
            data-testid="tickets-sumary-cards-wrapper"
            smallCards={smallCards}
            ownedCards={true}
          >
            {ticketOwnedCards.map(({ key, ...props }) => (
              <TicketCard
                key={key}
                cardKey={key}
                handleCardClick={handleCardClick}
                number={numberOfTickets(key)}
                smallCards={smallCards}
                {...props}
              />
            ))}
          </CardsWrapper>
        </>
      )}
    </SummaryContainer>
  );
};
